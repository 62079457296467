class ROLES{
    //用户web_auth_id h5权限 0普通用户 1管理员 2立项员 3运输单位 4审核人 5审批人
     ROLE_USER = 0; //普通用户
     ROLE_ADMIN = 1; //管理员
     ROLE_APPLICANT = 2; //立项员
     ROLE_BIDDER = 3; //运输单位
     ROLE_AUDIT = 4; //审核人
     ROLE_APPROVAL = 5; //一级审批人
     ROLE_APPROVAL_TWO = 6; //二级审批人
}
class PROJECT_STATUS{
    //项目状态0禁用//1立项中//2报价中//3一级审批中//4审批中//5完成
    PROJECT_STATUS_DISABLED = 0;
    PROJECT_STATUS_APPLY = 1;
    PROJECT_STATUS_QUOTE = 2;
    PROJECT_STATUS_APPROVAL = 3;
    PROJECT_STATUS_APPROVAL_TWO = 4;
    PROJECT_STATUS_COMPLETE = 5;
}
export default {

    install(Vue,options){

        Vue.prototype.global = {
            ROLES:new ROLES(),
            PROJECT_STATUS:new PROJECT_STATUS()
        };

    }

}

