<template>
  <div>
      <el-form :model="form">
        <el-form-item label="登录名" :label-width="formLabelWidth">
          <el-input clearable
                    v-model="admin.info.username"
                    disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="原密码" :label-width="formLabelWidth">
          <el-input clearable v-model="form.old_psw" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth">
          <el-input clearable show-password
                    type="password"
                    v-model="form.new_psw"
                    autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" :label-width="formLabelWidth">
          <el-input clearable show-password
                    type="password"
                    v-model="form.password2"
                    autocomplete="off"
                    @keyup.enter.native="edit"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="display: flex;width: 100%;flex:1;justify-content: center">
        <el-button type="primary" @click="edit()">确 定</el-button>
      </div>
<!--    <el-dialog title="绑定微信用户" append-to-body :visible.sync="bindWechatUserDialog.show" width="35%">-->
<!--      <div style="padding:30px" v-if="bindWechatUserDialog.QRCode.qr_h5">-->
<!--        <img :src="'data:image/png;base64,'+bindWechatUserDialog.QRCode.qr_h5" style="margin:30px;width:200px;height:200px">-->
<!--        <div style="width:100%;text-align:center">请用本人微信扫一扫绑定管理员</div>-->
<!--      </div>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Personal",
  computed: {
      ...mapGetters(["admin"]),
  },
  mounted(){

  },
  data() {
    return {
      formLabelWidth:'120px',
      visible: false,
      form: {
        old_psw: "",
        new_psw: "",
        password2: "",
      },
      bindWechatUserDialog:{
        show:true,
        QRCode:{
          qr_h5:''
        }
      },
      newBindUser:false,
    };
  },
  methods: {
    handleBindWechatUser(){
      this.newBindUser = true;
        this.bindWechatUserDialog.QRCode.qr_h5 = '';
        this.http.post(
          'system/admin/get_qrcode_list',
          {
            'type':'bindWechatUser',
            'id':this.admin.info.id
          }
        ).then(res => {
          if (res.status == 200) {
            this.bindWechatUserDialog.QRCode.qr_h5 = res.data.qr_h5;
          }
        })
      },
    handleClose() {
      this.dialogVisible = false;
    },
    edit() {
      if (this.form.new_psw !== this.form.password2) {
        this.$message({
          message: "两次输入的密码不一致",
          type: "warning",
        });
        return;
      }
      this.http.post("admin/change_password", this.form).then((res) => {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.form = {
          old_psw: "",
          new_psw: "",
          password2: "",
        };
        this.dialogFormVisible = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
