
  <div class="header-notice">
      <div @click="handleNoticeClick">
        <el-badge :value="needList.length?needList.length:0" class="item">
          <i class="el-icon-close-notification" style="font-size:18px"/>
        </el-badge>
      </div>
      <el-drawer
        title="通知"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose">
        <el-card class="box-card" v-if="needList.length">
          <div slot="header" class="clearfix">
            <span>系统更新通知</span>
          </div>
          <div v-for="(item,index) in needList" :key="index" @click="handleUpdateClick">
            系统已升级，最新版本{{ item.version }}
          </div>
        </el-card>

      </el-drawer>
<!--      <el-dropdown-menu slot="dropdown">-->
<!--        <el-dropdown-item command="update" v-for="(item,index) in needList" :key="index"><i class="el-icon-warning"-->
<!--                                                                                            style="font-size:10px"/>-->
<!--          系统已升级，最新版本{{ item.version }}-->
<!--        </el-dropdown-item>-->
<!--      </el-dropdown-menu>-->
  </div>
