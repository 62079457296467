
<el-dialog
  class="design-dialog"
  :wrapClassName="wrapClassName"
  :width="width"
  :visible="visible"
  :title="title"
  :zIndex="zIndex"
  :centered="centered"
  :okText="okText"
  :cancelText="cancelText"
  :confirmLoading="confirmLoading"
  @cancel="handleCancel"
  @ok="handleOk"
>
  <slot></slot>
  <template slot="footer">
    <slot name="footer"></slot>
  </template>
</el-dialog>
