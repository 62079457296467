import Cookies from "js-cookie";
import { getLanguage } from "@/lang/index";

const state = {
  /**
   * 是否需要强制登录
   */
  forcedLogin: false,
  hasLogin: false,
  userName: "",
  pageCur: "index",
  loading: true,
  user_info: "",
  openid: "",
  CategoryTreeData: [],
  menuActiveIndex: "1",
};

const mutations = {
  login(state, userName) {
    state.userName = userName || "新用户";
    state.hasLogin = true;
  },
  setPageCur(state, pageCur) {
    state.pageCur = pageCur;
  },
  setMenuActiveIndex(state, index) {
    state.menuActiveIndex = index;
    localStorage.setItem("act", index);
  },
  switch_loading(state, tf) {
    if (typeof tf === "undefined") {
      state.loading = !state.loading;
    } else {
      state.loading = tf;
    }
  },
  setOpenid(state, openid) {
    state.openid = openid;
  },
  setCategoryTreeData(state, data) {
    state.CategoryTreeData = data;
  },
};

const actions = {
  toggleSideBar({ commit }) {
    commit("TOGGLE_SIDEBAR");
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit("CLOSE_SIDEBAR", withoutAnimation);
  },
  toggleDevice({ commit }, device) {
    commit("TOGGLE_DEVICE", device);
  },
  setLanguage({ commit }, language) {
    commit("SET_LANGUAGE", language);
  },
  setSize({ commit }, size) {
    commit("SET_SIZE", size);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
