
<el-dropdown
  trigger="click"
  class="international"
  @command="handleSetLanguage"
>
  <div>
    <svg-icon class-name="international-icon" icon-class="language" />
  </div>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item :disabled="language === 'zh'" command="zh">
      中文
    </el-dropdown-item>
    <el-dropdown-item :disabled="language === 'en'" command="en">
      English
    </el-dropdown-item>
    <el-dropdown-item :disabled="language === 'es'" command="es">
      Español
    </el-dropdown-item>
    <el-dropdown-item :disabled="language === 'ja'" command="ja">
      日本語
    </el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown>
