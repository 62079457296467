
<div
  v-if="isExternal"
  :style="styleExternalIcon"
  class="svg-external-icon svg-icon"
  v-on="$listeners"
/>
<svg v-else :class="svgClass" aria-hidden="true" v-on="$listeners">
  <use :href="iconName" />
</svg>
