
<div
  v-if="visible"
  ref="dom"
  :data-id="id"
  style="text-align: center; margin-top: 10px"
>
  <img
    src="https://css.zafcdn.com/imagecache/MZF/images/loading_zf.gif"
    style="width: 0.96rem; height: 0.96rem"
  />
</div>
