
<div class="copyright">
  <div
    style="
      width: 100%;
      background-color: #ffffff;
      margin: 10px 0px;
      line-height: 40px;
    "
  ></div>
</div>
