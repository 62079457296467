//如果独立站点部署请配置新的URL
//整站接口请求地址

let HOST = "";
let THINKPHP_APP = "/";
let API_URL;

// 管理后台 socket 通信地址
let WS_ADMIN_URL = "";
// 客服 socket 通信地址
let WS_KEFU_URL = "";

if (process.env.NODE_ENV === "development") {
  //测试环境默认请求演示站数据
  // API_URL = "http://" + (HOST || `${window.location.hostname}`) + THINKPHP_APP;
  API_URL = "/api/";
  WS_ADMIN_URL = `ws:${HOST}:20082`;
  WS_KEFU_URL = `ws:${HOST}:20083`;
} else {
  // 生产环境请求接口地址 如果没有配置自动获取当前网址路径，如果独立部署请自行配置请求URL
  API_URL = `${window.location.origin}` + THINKPHP_APP;
  WS_ADMIN_URL = WS_ADMIN_URL || `ws:${window.location.hostname}:20002`;
  WS_KEFU_URL = WS_KEFU_URL || `ws:${window.location.hostname}:20003`;
}

const VUE_APP_API_URL = API_URL;
const VUE_APP_WS_ADMIN_URL = WS_ADMIN_URL;
const VUE_APP_WS_KEFU_URL = WS_KEFU_URL;

export default {
  title: "",
  // 接口请求地址
  apiBaseURL: VUE_APP_API_URL,
  // adminsocket连接
  wsAdminSocketUrl: VUE_APP_WS_ADMIN_URL,
  // kefusocket连接
  wsKefuSocketUrl: VUE_APP_WS_KEFU_URL,
  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: false,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   Vue.config.errorHandler   */
  sidebarLogo: true,
  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: "production",
  //超时请求白名单
  timeoutWhiteUrl: ["create_qrcode", "development"]
};
