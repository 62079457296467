<template>
  <el-scrollbar
    ref="scrollContainer"
    :vertical="false"
    class="scroll-container"
    @wheel.native.prevent="handleScroll"
  >
    <slot />
  </el-scrollbar>
</template>

<script>
const tagAndTagSpacing = 4; // tagAndTagSpacing

export default {
  name: "ScrollPane",
  data() {
    return {
      left: 0,
    };
  },
  computed: {
    scrollWrapper() {
      return this.$refs.scrollContainer.$refs.wrap;
    },
  },
  methods: {
    handleScroll(e) {
      const eventDelta = e.wheelDelta || -e.deltaY * 40;
      const $scrollWrapper = this.scrollWrapper;
      $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4;
    },
    moveToTarget(currentTag) {
      const $container = this.$refs.scrollContainer.$el;
      const $containerWidth = $container.offsetWidth;
      const $scrollWrapper = this.scrollWrapper;
      const tagList = this.$parent.$refs.tag;

      let firstTag = null;
      let lastTag = null;

      // find first tag and last tag
      if (tagList.length > 0) {
        firstTag = tagList[0];
        lastTag = tagList[tagList.length - 1];
      }

      if (firstTag === currentTag) {
        $scrollWrapper.scrollLeft = 0;
      } else if (lastTag === currentTag) {
        $scrollWrapper.scrollLeft =
          $scrollWrapper.scrollWidth - $containerWidth;
      } else {
        // find preTag and nextTag
        const currentIndex = tagList.findIndex((item) => item === currentTag);
        const prevTag = tagList[currentIndex - 1];
        const nextTag = tagList[currentIndex + 1];

        // the tag's offsetLeft after of nextTag
        const afterNextTagOffsetLeft =
          nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagAndTagSpacing;

        // the tag's offsetLeft before of prevTag
        const beforePrevTagOffsetLeft =
          prevTag.$el.offsetLeft - tagAndTagSpacing;

        if (
          afterNextTagOffsetLeft >
          $scrollWrapper.scrollLeft + $containerWidth
        ) {
          $scrollWrapper.scrollLeft = afterNextTagOffsetLeft - $containerWidth;
        } else if (beforePrevTagOffsetLeft < $scrollWrapper.scrollLeft) {
          $scrollWrapper.scrollLeft = beforePrevTagOffsetLeft;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-container {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50px;

  ::v-deep {
    .el-scrollbar__bar {
      bottom: 0;
    }
    .el-scrollbar__wrap {
      height: 44px;
      overflow: hidden;
    }
  }

  //#scroll{
  //  width:200px;
  //  height:200px;
  //  overflow:hidden;
  //  margin-bottom: 20px;
  //}
  //#scroll div{
  //  width:400px;
  //  height:400px;
  //}
  //#scroll::-webkit-scrollbar{/*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
  //  width:10px;
  //  height:10px;
  //}
  ///*#scroll::-webkit-scrollbar-button{!*滚动条两端的按钮，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置2）*!*/
  ///*background:#fff;*/
  ///*}*/
  //#scroll::-webkit-scrollbar-track{/*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
  //  background:#f8f9fa;
  //}
  //#scroll::-webkit-scrollbar-track-piece{/*内层轨道，滚动条中间部分（位置4）*/
  //  background:#f8f9fa;
  //}
  //#scroll::-webkit-scrollbar-thumb{/*滚动条里面可以拖动的那部分（位置5）*/
  //  background:#00000033;
  //  border-radius:4px;
  //}
  //#scroll::-webkit-scrollbar-corner {/*边角（位置6）*/
  //  background:#c0c0c0;
  //}
  ///*#scroll::-webkit-scrollbar-resizer  {!*定义右下角拖动块的样式（位置7）*!*/
  ///*background:#FF0BEE;*/
  ///*}*/
  //#scroll{
  //  scrollbar-arrow-color: #f4ae21; /**//*三角箭头的颜色*/
  //  scrollbar-face-color: #333; /**//*立体滚动条的颜色*/
  //  scrollbar-3dlight-color: #666; /**//*立体滚动条亮边的颜色*/
  //  scrollbar-highlight-color: #666; /**//*滚动条空白部分的颜色*/
  //  scrollbar-shadow-color: #999; /**//*立体滚动条阴影的颜色*/
  //  scrollbar-darkshadow-color: #666; /**//*立体滚动条强阴影的颜色*/
  //  scrollbar-track-color: #666; /**//*立体滚动条背景颜色*/
  //  scrollbar-base-color:#f8f8f8; /**//*滚动条的基本颜色*/
  //}


}
</style>
