import axios from "axios";
import { Message } from "element-ui";
import { removeCookies } from "@/libs/util.js";
import API_URL from "@/common/config.js";
import router from "@/router";
import { getToken } from "@/utils/auth";

const service = axios.create({
  baseURL: API_URL,
  timeout: 10000, // 请求超时时间
});

axios.defaults.withCredentials = false; // 携带cookie

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (config.kefu) {
      config.baseURL = API_URL.replace(/adminapi/, "kefuapi");
    } else {
      config.baseURL = API_URL;
    }

    let token = getToken();
    if (token) {
      config.headers["token"] = token;
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    let status = response.data ? response.data.status : 0;
    const code = status;
    switch (code) {
      case 200:
        return response.data;
      case 400:
      case 400011:
      case 400012:
        return Promise.reject(response.data || { msg: "未知错误" });
      case 410000:
      case 410001:
      case 410002:
        console.log(code);
        localStorage.clear();
        removeCookies("token");
        removeCookies("expires_time");
        removeCookies("uuid");
        router.replace({ path: "/admin/login" });
        break;
      case 410003:
        localStorage.clear();
        removeCookies("kefuInfo");
        removeCookies("kefu_token");
        removeCookies("kefu_expires_time");
        removeCookies("kefu_uuid");
        router.replace({ path: "/kefu" });
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
