import defaultSettings from "@/settings";
import i18n from "@/lang";

let title = defaultSettings.title;

export default function getPageTitle(key) {
  if(!title) {
    const siteInfo = localStorage.getItem('siteInfo') ;
    if(siteInfo) {
      title = JSON.parse(siteInfo)["site_name"];
    }
  }
  const hasKey = i18n.te(`route.${key}`);
  let pageName = ''
  if (hasKey) {
    pageName = i18n.t(`route.${key}`);
  }
  // return `${pageName} - ${title}`;
  return `${title}`;
}
