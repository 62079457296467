
<div>
  <div>
    <i class="el-icon-setting right-menu-item" @click.stop="show = !show" />
  </div>

  <div ref="rightPanel" :class="{ show: show }" class="rightPanel-container">
    <div class="rightPanel-background" />
    <div class="rightPanel">
      <div class="rightPanel-items">
        <slot />
      </div>
    </div>
  </div>
</div>
