
<span
  :style="price_styles"
  v-if="Number(value) > Number(shopPrice)"
  :class="{
    js_market_wrap: true,
    my_shop_price: true,
    'is-del': del,
  }"
  :data-orgp="value"
  :data-currency="currency"
  :data-original_amount="value"
>
  {{ price_title }}￥{{ value }}
</span>
