import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import API_URL from "@/common/config.js";

// create an axios instance
const service = axios.create({
  baseURL: API_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    let token = getToken();
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["token"] = getToken();
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (parseInt(response.data.status)>=200 && parseInt(response.data.status)<300) {
      return response.data;
    } else {
      return Promise.reject(response.data);
    }
  },
  (error) => {
    if(error.response && error.response.status === 401){
      // MessageBox.confirm("令牌已失效，请您重新登录", "确认退出", {
      //   confirmButtonText: "重新登录",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   localStorage.clear(); //结果为401，表示无权限，或token过期；返回login页面
      //   store.dispatch("user/resetToken").then(() => {
      //     location.reload();
      //   });
      // });
      Message.error("令牌已失效，请您重新登录");
        localStorage.clear(); //结果为401，表示无权限，或token过期；返回login页面
        store.dispatch("user/resetToken").then(() => {
          location.reload();
        });
    }else{
      if(error.code !="ECONNABORTED"){
        showError(error);
      }
    }
    return Promise.reject(error);
  }
);

// function baseRequest(type, api, param) {
//   return new Promise((resolve, reject) => {
//     service({
//       method: type,
//       api,
//       data: param,
//     })
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         showError(err);
//         reject(err);
//       });
//   });
// }
//
// const request = {};
//
// ["options", "get", "post", "put", "head", "delete", "trace", "connect"].forEach(
//   (method) => {
//     request[method] = (api, data) => baseRequest(method, api, data);
//   }
// );
// export default request;
export default {
  //get请求
  get(url, param) {
    return new Promise((resolve, reject) => {
      service({
        method: "get",
        url,
        params: param,
      })
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response?err.response.data.data:err);
        });
    });
  },

  put(url, param) {
    return new Promise((resolve, reject) => {
      service
        .put(url, param)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response?err.response.data.data:err);
        });
    });
  },

  post(url, param, callback = null) {
    return new Promise((resolve, reject) => {
      service({
        method: "post",
        url,
        data: param,
      })
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response?err.response.data.data:err);
        });
    });
  },
  delete(url, param) {
    const that = this;
    return new Promise((resolve, reject) => {
      service({
        method: "delete",
        url,
        data: param,
      })
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response?err.response.data.data:err);
        });
    });
  },
  service,
};
export let request = service;
