import {request} from "@/common/axios.js";

export function get_diy_page_info(data) {
  // return new Promise((resolve, reject) => {
  //   const json = require('./json-data/get_diy_page_info.json');
  //   const res = json.data.data.filter(x => x.pageId == data.page_id);
  //   resolve(res[0]);
  // });
  return request.get("/diy_template/admin/get_diy_page_info", data);
}
export function get_diy_template_list(data) {
  return request.get("/diy_template/admin/get_diy_template_list", data);
}

export function get_diy_component_list(data) {
  return request.get("/diy_template/admin/get_diy_component_list", data);
}
export const design_get_component_list = () => {
  const json = import("./json-data/get_component_list.json");
  return json;
};
export function save_diy_page(data) {
  return request.post("/diy_template/admin/save_diy_page", data);
}

/**
 * 获取组件模版列表
 */
export const design_get_component_template_list = () => {
  const json = import("./json-data/get_component_template_list.json");
  return json;
};
