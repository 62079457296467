import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

/* Layout */
import Layout from '@/layout/index.vue'

/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    meta: {
      elIcon: 'el-icon-s-home',
      title: 'Home',
    },
    children: [
      {
        path: 'home',
        component: () => import('@/views/Home.vue'),
        name: 'Home',
        meta: { title: 'Home', elIcon: 'el-icon-s-home', affix: true }
      }
    ]
  },
  {
    path: '/product',
    component: Layout,
    meta: {
        title: 'Product',
        elIcon: 'el-icon-goods'
     },
    children: [
      {
        path: 'list',
        component: () => import('@/views/product/list.vue'),
        name: 'ProductList',
        meta: { title: 'ProductList',elIcon: 'el-icon-menu' }
      },
      {
        path: 'add',
        component: () => import('@/views/product/add.vue'),
        name: 'ProductAdd',
        meta: { title: 'ProductAdd' ,elIcon: 'el-icon-menu'}
      },
      {
        path: 'edit',
        component: () => import('@/views/product/add.vue'),
        name: 'ProductEdit',
        hidden: true,
        meta: { title: 'ProductEdit' ,elIcon: 'el-icon-menu'}
      },
      {
        path: 'category',
        component: () => import('@/views/product/category.vue'),
        name: 'ProductCategory',
        meta: { title: 'ProductCategory'  ,elIcon: 'el-icon-menu'}
      }
    ],
  },
  {
    path: '/code',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'Code',
      elIcon: 'el-icon-scan'
    },
    children: [
      {
        path: 'code_manage',
        component: () => import('@/views/code/CodeManage.vue'),
        name: 'CodeManage',
        meta: { title: 'CodeManage' ,elIcon: 'el-icon-menu'}
      },
      {
        path: 'batch',
        component: () => import('@/views/code/batch/BatchManage.vue'),
        name: 'BatchManage',
        meta: {title: 'BatchManage', elIcon: 'el-icon-menu'}
      }
    ],
  },

  {
    path: '/certify_result',
    component: Layout,
    meta: {
      title: 'CertifyResult',
      elIcon: 'el-icon-s-data'
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/code/CertifyResultList.vue'),
        name: 'CertifyResultList',
        meta: { title: 'CertifyResultList',elIcon: 'el-icon-menu'  }
      },
      {
        path: 'total',
        component: () => import('@/views/statistics/total.vue'),
        name: 'StatisticsTotal',
        meta: { title: 'StatisticsTotal' ,elIcon: 'el-icon-menu' }
      },
      {
        path: 'genuine',
        component: () => import('@/views/statistics/genuine.vue'),
        name: 'StatisticsGenuine',
        meta: { title: 'StatisticsGenuine' ,elIcon: 'el-icon-menu' }
      },
      {
        path: 'counterfeit',
        component: () => import('@/views/statistics/counterfeit.vue'),
        name: 'StatisticsCounterfeit',
        meta: { title:'StatisticsCounterfeit' ,elIcon: 'el-icon-menu' }
      }
    ],
  },
  {
    path: '/writeoff',
    component: Layout,
    meta: {
      title: 'WriteoffResult',
      elIcon: 'el-icon-help'
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/writeoff/List.vue'),
        name: 'WriteoffResultList',
        meta: { title: 'WriteoffResultList' ,elIcon: 'el-icon-menu' }
      },
      {
        path: 'statistics',
        component: () => import('@/views/writeoff/statictics/index.vue'),
        name: 'WriteoffResultStatistics',
        meta: { title: 'WriteoffResultStatistics',elIcon: 'el-icon-menu'  }
      },
    ],
  },
  {
    path: '/warning',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'Warning',
      elIcon: 'el-icon-counterfeit-warning'
    },
    children: [
      {
        path: 'counterfeit',
        component: () => import('@/views/warning/counterfeit.vue'),
        name: 'WarningCounterfeit',
        meta: { title: 'WarningCounterfeit' ,elIcon: 'el-icon-menu' }
      },

    ],
  },
  // {
  //   path: '/article',
  //   component: Layout,
  //   alwaysShow: true,
  //   meta: {
  //     title: 'Article',
  //     elIcon: 'el-icon-document'
  //   },
  //   children: [
  //
  //     {
  //       path: 'article',
  //       component: () => import('@/views/article/article.vue'),
  //       name: 'ArticleList',
  //       meta: { title: 'ArticleList',elIcon: 'el-icon-menu' }
  //     },
  //     {
  //       path: 'category',
  //       component: () => import('@/views/article/category.vue'),
  //       name: 'AritcleCategory',
  //       meta: { title:'AritcleCategory' ,elIcon: 'el-icon-menu' }
  //     }
  //   ],
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    hidden: true,
    meta: {
      title: 'Login',
    }
  },

  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: '/trace',
    component: () => import('@/views/trace/index.vue'),
    hidden: true,
    meta: {
      title: 'Trace',
    }
  },
  // {
  //   path: '/login',
  //   component: () => import('@/views/login/index'),
  //   hidden: true
  // },
  // {
  //   path: '/auth-redirect',
  //   component: () => import('@/views/login/auth-redirect'),
  //   hidden: true
  // },
  {
    path: '/404',
    component: () => import('@/views/error-page/404.vue'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401.vue'),
    hidden: true
  },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/dashboard',
  //   children: [
  //     {
  //       path: 'dashboard',
  //       component: () => import('@/views/dashboard/index'),
  //       name: 'Dashboard',
  //       meta: { title: 'dashboard', icon: 'dashboard', affix: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/documentation',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/documentation/index'),
  //       name: 'Documentation',
  //       meta: { title: 'documentation', icon: 'documentation', affix: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/guide',
  //   component: Layout,
  //   redirect: '/guide/index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/guide/index'),
  //       name: 'Guide',
  //       meta: { title: 'guide', icon: 'guide', noCache: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/profile',
  //   component: Layout,
  //   redirect: '/profile/index',
  //   hidden: true,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/profile/index'),
  //       name: 'Profile',
  //       meta: { title: 'profile', icon: 'user', noCache: true }
  //     }
  //   ]
  // }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [

  {
    path: '/user',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'User',
      elIcon: 'el-icon-user'
    },
    children: [
      {
        path: 'fans_list',
        component: () => import('@/views/user/FansList.vue'),
        name: 'FansList',
        meta: {
          title: 'FansList',
          roles: ['admin', 'user'],
          elIcon: 'el-icon-menu'
        }
      },
      {
        path: 'user',
        component: () => import('@/views/user/user.vue'),
        name: 'UserList',
        meta: {
          title: 'UserList',
          roles: ['admin'],
          elIcon: 'el-icon-menu'
        }
      }

    ],
  },
  // {
  //   path: '/submit',
  //   component: Layout,
  //   alwaysShow: true,
  //   meta: {
  //     title: 'Submit',
  //     elIcon: 'el-icon-user'
  //   },
  //   children: [
  //     {
  //       path: 'feedback',
  //       component: () => import('@/views/submit/Feedback.vue'),
  //       name: 'Feedback',
  //       meta: {
  //         elIcon: 'el-icon-menu',
  //         title: 'Feedback',
  //         roles: ['admin', 'user'], // you can set roles in root nav
  //       }
  //     },
  //     // {
  //     //   path: 'askprice',
  //     //   component: () => import('@/views/submit/AskPrice.vue'),
  //     //   name: 'AskPrice',
  //     //   meta: {
  //     //     title: 'AskPrice',
  //     //     roles: ['admin'],
  //     //     elIcon: 'el-icon-menu'
  //     //   }
  //     // }
  //   ],
  // },
  {
    path: '/set',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'SystemSetting',
      elIcon: 'el-icon-setting'
    },
    children: [
      {
        path: '',
        component: () => import('@/views/set/set.vue'),
        name: 'SystemSetting',
        meta: { title: 'SystemSetting',roles: ['admin', 'user'] ,elIcon: 'el-icon-menu' }
      },
      // {
      //   path: 'ad',
      //   component: () => import('@/views/ad/ad.vue'),
      //   name: 'AdList',
      //   meta: { title: 'AdList' ,elIcon: 'el-icon-menu' }
      // },
      // {
      //   path: 'backup',
      //   component: () => import('@/views/backup/backup.vue'),
      //   name: 'DataBack',
      //   hidden: false,
      //   meta: { title: 'DataBack',elIcon: 'el-icon-menu',
      //     roles: ['admin'],
      //   }
      // },
      {
        path: 'system_update',
        component: () => import('@/views/system/system-update.vue'),
        name: 'SystemUpdate',
        hidden: false,
        meta: {
          title: 'SystemUpdate', elIcon: 'el-icon-menu',
          roles: ['admin', 'user'],
        }
      }
    ]
  },
  {
    path: '/file-manager/images.html',
    name: `images`,
    hidden: true,
    meta: {
      title: '上传图片',
      elIcon: 'el-icon-menu'
    },
    component: () => import('@/components/UploadFile/UEditorImages.vue')
  },
  {
    path: '/file-manager/video.html',
    name: `UEditorVideo`,
    hidden: true,
    meta: {
      title: '上传视频',
      elIcon: 'el-icon-menu'
    },
    component: () => import('@/components/UploadFile/UploadVideo/index.vue')
  },
  // {
  //   path: '/logout',
  //   component: Layout,
  //   meta: {
  //     title: 'LogOut',
  //     elIcon: 'el-icon-quit',
  //
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/logout/logout.vue'),
  //       name: 'LogOut',
  //       meta: {
  //         title: 'LogOut',
  //         elIcon: 'el-icon-quit',
  //         breadcrumb:false,
  //         roles: ['admin', 'user'],
  //       }
  //     },
  //
  //   ],
  // },
  // {
  //   path: '/permission',
  //   component: Layout,
  //   redirect: '/permission/page',
  //   alwaysShow: true, // will always show the root menu
  //   name: 'Permission',
  //   meta: {
  //     title: 'permission',
  //     icon: 'lock',
  //     roles: ['admin', 'editor'] // you can set roles in root nav
  //   },
  //   children: [
  //     {
  //       path: 'page',
  //       component: () => import('@/views/permission/page'),
  //       name: 'PagePermission',
  //       meta: {
  //         title: 'pagePermission',
  //         roles: ['admin'] // or you can only set roles in sub nav
  //       }
  //     },
  //     {
  //       path: 'directive',
  //       component: () => import('@/views/permission/directive'),
  //       name: 'DirectivePermission',
  //       meta: {
  //         title: 'directivePermission'
  //         // if do not set roles, means: this page does not require permission
  //       }
  //     },
  //     {
  //       path: 'role',
  //       component: () => import('@/views/permission/role'),
  //       name: 'RolePermission',
  //       meta: {
  //         title: 'rolePermission',
  //         roles: ['admin']
  //       }
  //     }
  //   ]
  // },
  //
  // {
  //   path: '/icon',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/icons/index'),
  //       name: 'Icons',
  //       meta: { title: 'icons', icon: 'icon', noCache: true }
  //     }
  //   ]
  // },
  //
  // /** when your routing map is too long, you can split it into small modules **/
  // // componentsRouter,
  // // chartsRouter,
  // // nestedRouter,
  // // tableRouter,
  //
  // {
  //   path: '/example',
  //   component: Layout,
  //   redirect: '/example/list',
  //   name: 'Example',
  //   meta: {
  //     title: 'example',
  //     icon: 'example'
  //   },
  //   children: [
  //     {
  //       path: 'create',
  //       component: () => import('@/views/example/create'),
  //       name: 'CreateArticle',
  //       meta: { title: 'createArticle', icon: 'edit' }
  //     },
  //     {
  //       path: 'edit/:id(\\d+)',
  //       component: () => import('@/views/example/edit'),
  //       name: 'EditArticle',
  //       meta: { title: 'editArticle', noCache: true, activeMenu: '/example/list' },
  //       hidden: true
  //     },
  //     {
  //       path: 'list',
  //       component: () => import('@/views/example/list'),
  //       name: 'ArticleList',
  //       meta: { title: 'articleList', icon: 'list' }
  //     }
  //   ]
  // },
  //
  // {
  //   path: '/tab',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/tab/index'),
  //       name: 'Tab',
  //       meta: { title: 'tab', icon: 'tab' }
  //     }
  //   ]
  // },
  //
  // {
  //   path: '/error',
  //   component: Layout,
  //   redirect: 'noRedirect',
  //   name: 'ErrorPages',
  //   meta: {
  //     title: 'errorPages',
  //     icon: '404'
  //   },
  //   children: [
  //     {
  //       path: '401',
  //       component: () => import('@/views/error-page/401'),
  //       name: 'Page401',
  //       meta: { title: 'page401', noCache: true }
  //     },
  //     {
  //       path: '404',
  //       component: () => import('@/views/error-page/404'),
  //       name: 'Page404',
  //       meta: { title: 'page404', noCache: true }
  //     }
  //   ]
  // },
  //
  // {
  //   path: '/error-log',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'log',
  //       component: () => import('@/views/error-log/index'),
  //       name: 'ErrorLog',
  //       meta: { title: 'errorLog', icon: 'bug' }
  //     }
  //   ]
  // },
  //
  // {
  //   path: '/excel',
  //   component: Layout,
  //   redirect: '/excel/export-excel',
  //   name: 'Excel',
  //   meta: {
  //     title: 'excel',
  //     icon: 'excel'
  //   },
  //   children: [
  //     {
  //       path: 'export-excel',
  //       component: () => import('@/views/excel/export-excel'),
  //       name: 'ExportExcel',
  //       meta: { title: 'exportExcel' }
  //     },
  //     {
  //       path: 'export-selected-excel',
  //       component: () => import('@/views/excel/select-excel'),
  //       name: 'SelectExcel',
  //       meta: { title: 'selectExcel' }
  //     },
  //     {
  //       path: 'export-merge-header',
  //       component: () => import('@/views/excel/merge-header'),
  //       name: 'MergeHeader',
  //       meta: { title: 'mergeHeader' }
  //     },
  //     {
  //       path: 'upload-excel',
  //       component: () => import('@/views/excel/upload-excel'),
  //       name: 'UploadExcel',
  //       meta: { title: 'uploadExcel' }
  //     }
  //   ]
  // },
  //
  // {
  //   path: '/zip',
  //   component: Layout,
  //   redirect: '/zip/download',
  //   alwaysShow: true,
  //   name: 'Zip',
  //   meta: { title: 'zip', icon: 'zip' },
  //   children: [
  //     {
  //       path: 'download',
  //       component: () => import('@/views/zip/index'),
  //       name: 'ExportZip',
  //       meta: { title: 'exportZip' }
  //     }
  //   ]
  // },
  //
  // {
  //   path: '/pdf',
  //   component: Layout,
  //   redirect: '/pdf/index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/pdf/index'),
  //       name: 'PDF',
  //       meta: { title: 'pdf', icon: 'pdf' }
  //     }
  //   ]
  // },
  // {
  //   path: '/pdf/download',
  //   component: () => import('@/views/pdf/download'),
  //   hidden: true
  // },
  //
  // {
  //   path: '/theme',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/theme/index'),
  //       name: 'Theme',
  //       meta: { title: 'theme', icon: 'theme' }
  //     }
  //   ]
  // },
  //
  // {
  //   path: '/clipboard',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/clipboard/index'),
  //       name: 'ClipboardDemo',
  //       meta: { title: 'clipboardDemo', icon: 'clipboard' }
  //     }
  //   ]
  // },
  //
  // {
  //   path: '/i18n',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/i18n-demo/index'),
  //       name: 'I18n',
  //       meta: { title: 'i18n', icon: 'international' }
  //     }
  //   ]
  // },
  //
  // {
  //   path: 'external-link',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'https://github.com/PanJiaChen/vue-element-admin',
  //       meta: { title: 'externalLink', icon: 'link' }
  //     }
  //   ]
  // },
  //
  // // 404 page must be placed at the end !!!
  // { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher // reset router
}

export default router
