
<div class="ui-unit-discount" :style="style_body" v-if="visible">
  <label class="bold">
    <!-- 类型1 -->
    <template v-if="type == 1"> {{ value_parse }}%<br /><i>OFF</i> </template>

    <!-- 类型2 -->
    <template v-else> -{{ value_parse }}% </template>
  </label>
</div>
